import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import React from "react"
import {
  PageBuilderActiviteitFaqFragment,
  PageBuilderErvaringFaqFragment,
  PageBuilderFaqFragment,
  PageBuilderNieuwsFaqFragment,
  PageBuilderVacatureFaqFragment,
} from "../../__generated__/graphql-gatsby"
import CollapseBox from "../collapseBox"

interface IProps {
  block:
    | PageBuilderFaqFragment
    | PageBuilderErvaringFaqFragment
    | PageBuilderActiviteitFaqFragment
    | PageBuilderNieuwsFaqFragment
    | PageBuilderVacatureFaqFragment
}

const FAQ: React.FC<IProps> = ({ block }) => {
  const headerColor = block.kleur as MuiColors
  const text = block.tekst ? block.tekst : ""

  return (
    <CollapseBox title={String(block.titel)} color={headerColor}>
      <Typography
        variant="body2"
        component={Box}
        px={3}
        py={2.5}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </CollapseBox>
  )
}

export default FAQ
