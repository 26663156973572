import AppBar from "@material-ui/core/AppBar"
import Card from "@material-ui/core/Card"
import Collapse from "@material-ui/core/Collapse"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded"
import React, { useState } from "react"
import styled from "styled-components"

interface IProps {
  title: string
  color: "primary" | "secondary"
}

const Wrapper = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  @media (min-width: 930px) {
    max-width: 930px;
  }
`

const Header = styled(AppBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  cursor: pointer;

  .icon {
    transition: ${({ theme }) =>
      theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      })};
  }

  .open-icon {
    transform: rotate(0deg);
  }
  .closed-icon {
    transform: rotate(-180deg);
  }
`

const CollapseBox: React.FC<IProps> = ({ children, title, color }) => {
  const [expanded, setExpanded] = useState(false)
  const iconClass = expanded ? `closed-icon` : `open-icon`

  const toggle = () => setExpanded(s => !s)

  return (
    <Wrapper>
      <Card>
        <Header
          position="static"
          color={color}
          component="div"
          onClick={toggle}
        >
          <Typography variant="h6" component="span">
            {title}
          </Typography>

          <ExpandMoreRoundedIcon className={`icon ${iconClass}`} />
        </Header>
        <Collapse in={expanded} unmountOnExit>
          {children}
        </Collapse>
      </Card>
    </Wrapper>
  )
}

export default CollapseBox
